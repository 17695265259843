.lg-relative-caption {
    .lg-outer {
        .lg-sub-html {
            white-space: normal;
            bottom: auto;
            padding: 0;
            background-image: none;
        }
        .lg-relative-caption-item {
            opacity: 0;
            padding: 16px 0;
            transition: 0.5s opacity ease;
        }

        .lg-show-caption {
            .lg-relative-caption-item {
                opacity: 1;
            }
        }
    }
}
