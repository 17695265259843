.subscribe {
  background: #EDF5FD;
  border-radius: 5px;
  padding: 12px;
  .img {
    position: relative;
    margin-right: 16px;
    &:after{
      content: '3';
      display: block;
      font-size: 10px;
      background: #E73D3D;
      border: 2px solid #FFFFFF;
      border-radius: 100%;
      position: absolute;
      top: -5px;
      right: -5px;
      width: 20px;
      height: 20px;
      color: $white;
      line-height: 16px;
      text-align: center;
    }
  }
  .info {
    @include media-breakpoint-down(lg) {
      width: 250px;
      margin-top: -11px;
    }
  }
  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 2px;
    margin-top: 6px;
    color: $black;
    @include media-breakpoint-down(lg) {
      font-size: 14px;
    }
  }
  .text {
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #808080;
    @include media-breakpoint-down(lg) {
      font-size: 14px;
    }
  }
  .form {
    padding: 6px 13px;
    .form-group{
      margin: 0;
      .form-control {
        border: 0;
        height: 44px;
        line-height: 44px;
        border-radius: 5px 0 0 5px;
        width: 185px;
        font-size: 16px;
        @include media-breakpoint-down(lg) {
          width: 160px;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .btn {
      width: 140px;
      text-align: center;
      border-radius: 0 5px 5px 0;
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      @include media-breakpoint-down(lg) {
        width: 130px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
      }
    }
  }
}