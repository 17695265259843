.brands {
  .brand {
    a {
      background: #F0F0F0;
      border-radius: 5px;
      height: 139px;
      width: 100%;
      line-height: 139px;
      text-align: center;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  }
}