.characteristics {
  .size {
    position: relative;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    color: #808080;
    padding-left: 15px;
    height: 70px;
    margin-bottom: 30px;
    .svg {
      height: 70px;
    }
    .height {
      display: block;
      position: absolute;
      top: 28px;
      left: -35px;
      width: 70px;
      height: 7px;
      text-align: center;
      transform: rotate(-90deg);
      border-bottom: 1px solid #808080;
      i {
        display: inline-block;
        background: #fff;
        padding: 0 5px;
        font-style: normal;
      }
      &:before {
        content: '';
        position: absolute;
        top: -28px;
        left: 28px;
        background: url('../img/shared/arrow.svg') center top no-repeat transparent;
        height: 70px;
        width: 14px;
        transform: rotate(-90deg);
      }
      &:after {
        content: '';
        position: absolute;
        top: -29px;
        right: 28px;
        background: url("../img/shared/arrow.svg") center top no-repeat transparent;
        height: 70px;
        width: 14px;
        transform: rotate(90deg);
      }
    }
    .length {
      display: block;
      position: absolute;
      bottom: -14px;
      left: 15px;
      right: 0;
      height: 7px;
      text-align: center;
      border-bottom: 1px solid #808080;
      i {
        display: inline-block;
        background: #fff;
        padding: 0 5px;
        font-style: normal;
      }
      &:before {
        content: '';
        position: absolute;
        top: -28px;
        left: 28px;
        background: url('../img/shared/arrow.svg') center top no-repeat transparent;
        height: 70px;
        width: 14px;
        transform: rotate(-90deg);
      }
      &:after {
        content: '';
        position: absolute;
        top: -29px;
        right: 28px;
        background: url("../img/shared/arrow.svg") center top no-repeat transparent;
        height: 70px;
        width: 14px;
        transform: rotate(90deg);
      }
    }
    .clirens {
      display: block;
      position: absolute;
      top: 47px;
      right: -19px;
      width: 30px;
      height: 7px;
      text-align: center;
      transform: rotate(-90deg);
      border-bottom: 1px solid #808080;
      i {
        display: inline-block;
        background: #fff;
        padding: 0 5px;
        font-style: normal;
        margin-top: 8px;
      }
      &:before {
        content: '';
        position: absolute;
        top: -8px;
        left: 8px;
        background: url('../img/shared/arrow.svg') center top no-repeat transparent;
        height: 30px;
        width: 14px;
        transform: rotate(-90deg);
      }
      &:after {
        content: '';
        position: absolute;
        top: -9px;
        right: 8px;
        background: url("../img/shared/arrow.svg") center top no-repeat transparent;
        height: 30px;
        width: 14px;
        transform: rotate(90deg);
      }
    }
  }
  .charact-main {
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    color: #808080;
    span {
      display: block;
      color: #000;
      margin-bottom: 15px;
    }
  }
  .charact-all {
    margin-top: 20px;
    h3 {
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      margin: 15px 0 15px;
      padding: 0;
    }
    .name {
      font-weight: normal;
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 12px;
      color: #808080;
    }
    .val {
      font-weight: normal;
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 12px;
      color: #000000;

    }
  }
}