.sorting {
  padding: 0 15px 15px;
  .row {
    margin: 0 -10px;
  }
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
  .form-control {
    height: 36px;
    line-height: 36px;
    padding: 0 20px 0 9px;
    font-size: 16px;
    color: #808080;
    margin-bottom: 0;
    appearance: none;
    outline: none;
  }
  .form-group {
    margin-bottom: 0;
    .form__label {
      left: 9px;
      top: 1px;
      font-size: 8px;
    }
  }
  select.form-control {
    background: url("../img/filter/open.svg") right 9px  top 16px no-repeat transparent;
    font-size: 12px;
    line-height: 14px;
    white-space: pre-wrap;
    &.empty {
      font-size: 16px;
      line-height: 36px;
    }

  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]:hover,
  input[type="number"]:focus {
    -moz-appearance: number-input;
  }
  .duo {
    .col-6:first-child{padding-right: 5px}
    .col-2:first-child{padding-right: 5px}
    .col-6:last-child{padding-left: 5px}
  }
  .same {
    .col-6:first-child{
      padding-right: 0;
      .form-control {
        border-radius: 5px 0 0 5px;
      }
    }
    .col-6:last-child{
      padding-left: 0;
      .form-control {
        border-radius: 0 5px 5px 0;
        border-left: 0;
      }
    }
  }
  .sorting-choose {
    padding-right: 70px;
    .sorting-choose-item {
      text-decoration: none;
      padding: 4px 12px;
      color: rgba(0,0,0,.6);
      font-size: 12px;
      border-radius: 12px;
      display: block;
      background: #dddddd;
      text-align: center;
      margin: 5px 0;
      white-space: nowrap;
      &:hover{
        color: $red;
        text-decoration: none;
      }
    }
    .owl-nav {
      position: absolute;
      right: 0;
      top: 5px;
    }
    .owl-nav button.owl-next, .owl-nav button.owl-prev {
      background:  #dddddd;
      color: rgba(0,0,0,.6);
      border: none;
      padding: 0!important;
      font: inherit;
      border-radius: 12px;
      height: 26px;
      width: 26px;
      line-height: 26px;
      text-align: center;
      font-size: 26px;
      margin-left: 5px;
      margin-top: -1px;
      outline: none;
      span {
        display: block;
        margin-top: -4px;
      }
    }
  }
}