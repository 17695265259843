.pagination {
  margin: 45px 0 30px;
  .btn {
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    font-size: 13px;
    @include media-breakpoint-down(md) {
      &.btn-primary {
      float: none!important;
      margin: 0 auto 20px;
      width: 200px;
      display: block;
      }
    }
  }
  .btn-default {
    margin-left: 10px;
    @include media-breakpoint-down(md) {
      margin: 0 20px;
    }
  }
  .fastbtn {
    min-width: 100px;
  }
  .pages {
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    margin: 0 20px;
    padding: 0;
    list-style: none;
    float: left;
    @include media-breakpoint-down(md) {
      float: none;
      display: block;
      margin-bottom: 20px;
    }
    li {
      border-left: 1px solid #DDDDDD;
      margin: 0;
      padding: 0;
      list-style: none;
      float: left;
      a,span {
        width: 37px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        text-decoration: none;
        font-size: 14px;
        color: $black;
        display: block;
        background: #ffffff;
        border-radius: 5px;
        @include media-breakpoint-down(md) {
          width: 40px;
          height: 40px;
          line-height: 40px;
        }
        &:hover {
          background: #FCFCFC;
        }
        &.active {
          background: #F0F0F0;
          cursor: default;
          &:hover {
            background: #F0F0F0;
          }
        }
      }
    }
    li:first-child{
      border-radius: 5px 0 0 5px;
      border-left: 0;
    }
    li:last-child{
      border-radius: 0 5px 5px 0;
      border-right: 0;
    }
  }
}