.sellform {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 20px 20px 11px;
  margin-bottom: 60px;
  .row {
    margin: 0 -10px;
  }
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
  .form-control {
    height: 36px;
    line-height: 36px;
    padding: 0 20px 0 9px;
    font-size: 16px;
    color: #808080;
    margin-bottom: 12px;
    appearance: none;
  }
  .form-group {
    .form__label {
      left: 9px;
      top: 1px;
      font-size: 8px;
    }
  }
  select.form-control {
    background: url("../img/filter/open.svg") right 9px  top 16px no-repeat transparent;
  }
  .duo {
    .col-6:first-child{padding-right: 5px}
    .col-2:first-child{padding-right: 5px}
    .col-6:last-child{padding-left: 5px}
  }.filter .range-from
   .same {
      .col-6:first-child {
        padding-right: 0;

        .form-control {
          border-radius: 5px 0 0 5px;
        }
      }
      .col-6:last-child {
        padding-left: 0;

        .form-control {
          border-radius: 0 5px 5px 0;
          border-left: 0;
        }
      }
  }
  .btn {
    font-size: 16px;
    height: 36px;
    line-height: 36px;
  }
  .link {
    font-size: 15px;
    height: 36px;
    line-height: 36px;
    display: block;
    color: #36A2BA;
    background: url("../img/filter/open_blue.svg") right 9px top 16px no-repeat transparent;
    cursor: pointer;
  }

  .irs--flat {
    .irs-line {
      top: 18px;
      height: 4px;
      background-color: #cccccc;
      border-radius: 4px;
      cursor: pointer;
    }
    .irs-bar {
      top: 18px;
      height: 4px;
      background-color: #36A2BA;
    }
    .irs-handle {
      top: 11px;
      width: 19px;
      height: 19px;
      background: #36A2BA;
      border: 3px solid #F0F0F0;
      border-radius: 100%;
      cursor: pointer;
      &>i:first-child {
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        width: 0;
        height: 100%;
        margin-left: -1px;
        background-color: transparent;
      }
    }
    .irs-from, .irs-single, .irs-to,.irs-min, .irs-max {
      display: none;
    }
  }
  .range-from {
    font-size: 14px;
    color: #808080;
    position: absolute;
    bottom: -6px;
    left: 0;
  }
  .range-to {
    font-size: 14px;
    color: #808080;
    position: absolute;
    bottom: -6px;
    right: 0;
  }
}