$red : #DB3727;
$black : #000000;
$gray-dark: #757575;
$gray-light: #E0E0E0;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1278px
);

$container-max-widths: (
    sm: 360px,
    md: 704px,
    lg: 912px,
    xl: 1196px
);
$container-min-widths: (
    sm: 360px,
    md: 704px,
    lg: 912px,
    xl: 1196px
);
.container {
  @include media-breakpoint-up(sm) {
    width: 360px;
  }
  @include media-breakpoint-up(md) {
    width: 704px;
  }
  @include media-breakpoint-up(xl) {
    width: 1278px;
  }
}