@import 'lg-variables';
@import 'lg-mixins';
.lg-comment-box {
    width: 420px;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    background-color: #fff;
    will-change: transform;
    @include transform(translate3d(100%, 0, 0));
    @include transitionCustom(transform 0.4s cubic-bezier(0, 0, 0.25, 1) 0s);

    .lg-comment-title {
        margin: 0;
        color: #fff;
        font-size: 18px;
    }

    .lg-comment-header {
        background-color: #000;
        padding: 12px 20px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    }

    .lg-comment-body {
        height: 100% !important;
        padding-top: 43px !important;
        width: 100% !important;
    }

    .fb-comments {
        height: 100%;
        width: 100%;
        background: url('#{$lg-path-images}/loading.gif') no-repeat scroll
            center center #fff;
        overflow-y: auto;
        display: inline-block;
        &[fb-xfbml-state='rendered'] {
            background-image: none;
        }
        > span {
            max-width: 100%;
        }
    }

    .lg-comment-close {
        position: absolute;
        right: 5px;
        top: 12px;
        cursor: pointer;
        font-size: 20px;
        color: #999;
        will-change: color;
        @include transition(color 0.2s linear);
        &:hover {
            color: #fff;
        }
        &:after {
            content: '\e070';
        }
    }

    iframe {
        max-width: 100% !important;
        width: 100% !important;
    }

    #disqus_thread {
        padding: 0 20px;
    }
}
.lg-outer {
    .lg-comment-overlay {
        background-color: rgba(0, 0, 0, 0.25);
        bottom: 0;
        cursor: default;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $zindex-toolbar - 1;
        opacity: 0;
        visibility: hidden;
        will-change: visibility, opacity;
        @include transition(
            visibility 0s linear 0.18s,
            opacity 0.18s linear 0s
        );
    }
    .lg-comment-toggle {
        &:after {
            content: '\e908';
        }
    }
    &.lg-comment-active {
        .lg-comment-overlay {
            @include transition-delay(0s);
            @include transform(translate3d(0, 0px, 0));
            opacity: 1;
            visibility: visible;
        }
        .lg-comment-toggle {
            color: #fff;
        }
        .lg-comment-box {
            @include transform(translate3d(0, 0, 0));
        }
    }
}
