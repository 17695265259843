.product {
  .product-header {
    margin-bottom: 60px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 30px;
    }
    .title {
      font-weight: bold;
      font-size: 32px;
      line-height: 41px;
      margin-bottom: 6px;
      @include media-breakpoint-down(lg) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .attrs {
      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }
      &:after {
        display: block;
        content: '';
        clear: both;
      }
      .checked {
        height: 26px;
        background: url("../img/shared/checked.svg") left top no-repeat transparent;
        padding-left: 25px;
        color: $black;
        font-size: 14px;
        line-height: 13px;
        width: 115px;
        margin: 4px 15px 4px 0;
        float: left;
      }
      .favourite {
        height: 35px;
        width: 35px;
        display: block;
        text-align: center;
        border: 1px solid #808080;
        border-radius: 5px;
        background: $white;
        z-index: 900;
        cursor: pointer;
        line-height: 35px;
        float: left;
        .svg {
          width: 15px;
          height: 14px;
          margin: -5px auto 0;
          path {
            fill: none;
            stroke: #808080;
            stroke-width: 4px;
          }
        }
        &.active {
          .svg {
            path {
              fill: #E73D3D;
              stroke: #E73D3D;
            }
          }
        }
        @include media-breakpoint-up(lg) {
          &:hover {
            .svg {
              path {
                fill: #E73D3D;
                stroke: #E73D3D;
              }
            }
          }
        }
      }
      .share {
        height: 35px;
        width: 35px;
        display: block;
        text-align: center;
        border: 1px solid #808080;
        border-radius: 5px;
        background: $white;
        z-index: 900;
        cursor: pointer;
        line-height: 35px;
        float: left;
        margin-left: 5px;
        background: url("../img/shared/share.svg") center center no-repeat transparent;
        .ya-share2__item_service_whatsapp .ya-share2__badge, .ya-share2__item_service_whatsapp .ya-share2__mobile-popup-badge {
          margin-right: 20px;
        }
        .ya-share2__container_size_m .ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__link_more.ya-share2__link_more-button-type_short {
          background: none;
        }
        .ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__icon_more {
          background: none;
        }
        .svg {
          width: 15px;
          height: 14px;
          margin: -5px auto 0;
          path {
            fill: none;
            stroke: #808080;
          }
        }
        &.active, &:hover {
          background-color: #f9f9f9;
        }
      }
    }
    .cost {
      font-weight: bold;
      font-size: 32px;
      line-height: 41px;
      text-align: right;
      color: #000000;
      margin-bottom: 2px;
      @include media-breakpoint-down(lg) {
        font-size: 24px;
        line-height: 32px;
      }
      @include media-breakpoint-down(md) {
        float: left;
        text-align: left;
      }
    }
    .credit {
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
      text-align: right;
      color: #36A2BA;
      margin-bottom: 8px;
      visibility: hidden;
      @include media-breakpoint-down(sm) {
        margin-bottom: 0px;
      }
    }
    .discount {
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      text-align: right;
      color: #000000;
    }
  }
  .product-info {
    .product-info-main {
      width: 305px;
      margin-top: -8px;
      margin-bottom: 80px;
      @include media-breakpoint-down(lg) {
        width: 100%;
        margin-bottom: 30px;
      }
      .name {
        font-weight: normal;
        font-size: 15px;
        line-height: 30px;
        color: #808080;
      }
      .val {
        font-weight: normal;
        font-size: 15px;
        line-height: 30px;
        color: #000000;
      }
    }
    .product-info-credit {
      width: 305px;
      @include media-breakpoint-down(lg) {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
  .product-buttons {
    margin-top: 40px;
    display: block;
    top: 200px;
    z-index: 700;
    width: 235px;
    margin-right: 0;
    margin-left: auto;
    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-top: 0;
      top: 180px;
    }
    a {
      font-size: 17px;
      background-position: left 14px center;
      background-repeat: no-repeat;
      padding-left: 45px;
      text-align: left;
      @include media-breakpoint-down(lg) {
        font-size: 16px;
        background-position: left 10px center;
        padding-left: 35px;
      }
    }
    .phone {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      width: 100%;
      padding: 10px 0;
      height: auto;
      margin-bottom: 15px;
      text-align: center;
      @include media-breakpoint-down(sm) {
        position: fixed;
        bottom: 0;
        left: 15px;
        right: 15px;
        width: auto;
      }
      span {
        font-size: 18px;
        line-height: 23px;
        display: block;
      }
    }
    .callback {
      background-image: url("../img/product/callback.svg");
      margin-bottom: 30px;
    }
    .application {
      background-image: url("../img/product/application.svg");
      margin-bottom: 15px;
    }
    .change {
      background-image: url("../img/product/change.svg");
      margin-bottom: 15px;
    }
    .credit {
      background-image: url("../img/product/credit.svg");
      margin-bottom: 15px;
    }
    .book {
      background-image: url("../img/product/book.svg");
      margin-bottom: 15px;
    }
  }
  .description {
    margin-bottom: 50px;
    h2 {
      font-size: 23px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
    }
    .collapse-control {
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
      color: #36A2BA;
      text-decoration: none;
      margin-bottom: 10px;
      &[aria-expanded="true"] {
        display: none;
      }
    }
  }
  .accordion {
    .card {
      .card-header {
        .card-link {
          width: 100%;
          height: 50px;
          border-bottom: 1px solid #DDDDDD;
          display: block;
          line-height: 50px;
          font-weight: normal;
          font-size: 14px;
          color: #000000;
          position: relative;
          cursor: pointer;
          span {
            display: block;
            height: 50px;
            position: absolute;
            top: 0;
            right: 30px;
            color: #808080;;
          }
          &:after {
            display: block;
            content: '';
            width: 10px;
            height: 50px;
            position: absolute;
            top: 0;
            right: 10px;
            background: url('../img/shared/collaps_down.svg') center center no-repeat transparent;
          }
          &[aria-expanded="true"] {
            border-bottom: 1px solid transparent;
            &:after {
              background-image: url("../img/shared/collaps_up.svg");
            }
          }
        }
      }
      .card-body {
        font-size: 14px;
        line-height: 14px;
        color: #808080;
        .card-item {
          margin-bottom: 10px;
        }
      }
    }
  }
}