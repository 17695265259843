.btn {
  display: inline-block;
  font-weight: normal;
  font-size: 18px;
  color: $white;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0;
  height: 42px;
  line-height: 42px;
  border-radius: 5px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-primary {
  color: #fff;
  background-color: #36A2BA;
  border-color: #36A2BA;
  &:hover, &:focus, &:active {
    background-color: #3892ba;
    border-color: #3892ba;
  }
  &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #3892ba;
    border-color: #3892ba;
  }
}
.btn-green {
  color: #fff;
  background-color: #55BB00;
  border-color: #55BB00;
  &:hover, &:focus, &:active {
    background-color: #67bb23;
    border-color: #67bb23;
    color: #fff;
  }
  &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #67bb23;
    border-color: #67bb23;
  }
}
.btn-blue {
  color: #fff;
  background-color: #36A2BA;
  border-color: #36A2BA;
  &:hover, &:focus, &:active {
    background-color: #35aeba;
    border-color: #35aeba;
    color: #fff;
  }
  &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #35aeba;
    border-color: #35aeba;
  }
}
.btn-info {
  font-size: 16px;
  color: #36A2BA;
  background-color: #ffffff;
  border-color: #36A2BA;
  &:hover, &:focus, &:active {
    color: #fff;
    background-color: #35aeba;
    border-color: #35aeba;
  }
  &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #35aeba;
    border-color: #35aeba;
  }
}

.btn-default {
  font-size: 16px;
  color: $black;
  background-color: #ffffff;
  border-color: #DDDDDD;
  &:hover, &:focus, &:active {
    color: $black;
    background-color: #ffffff;
    border-color: #999999;
  }
  &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-info.dropdown-toggle {
    color: $black;
    background-color: #ffffff;
    border-color: #999999;
  }
  &:disabled, .disabled {
    opacity: 0.5;
    cursor: default;
  }
}
.btn-transparent {
  background-color: transparent;
  color: $black;
  &:hover, &:focus, &:active {
    background-color: transparent;
    color: $black;
  }
  &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-info.dropdown-toggle {
    background-color: transparent;
    color: $black;
  }
}

.btn-full {
  display: block;
  width: 100%;
}
