.modal-dialog {
  width: 341px;
}
.modal-content {
  border-radius: 5px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  .modal-title {
    font-weight: bold;
    font-size: 17px;
  }

  .modal-header {
    padding: 16px 16px 11px;
    border-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .modal-body {
    padding: 0 16px;
  }

  .modal-footer {
    padding: 0 16px 16px;
    border-top: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}