.stage-points {
  padding-left: 25px;
  margin-top: 50px;
  width: 290px;
  display: flex;
  flex-flow: column;
  align-items: center;
  top: 200px;
  z-index: 700;
  .stage {
    border-left: 3px dashed #CCCCCC;
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 60px;
    position: relative;
    padding-left: 35px;
    width: 180px;
    span {
      display: flex;
      height: 50px;
      align-items: center;
    }
    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 100%;
      background: #F0F0F0;
      top: 0;
      left: -25px;
      text-align: center;
      font-size: 18px;
      color: #000;
    }
    &.active {
      font-weight: bold;
      &:before {
        color: #fff;
        font-weight: bold;
        background: #36A2BA;
      }
    }
    &.stage-1 {
      &:before {
        content: '1';
      }
    }
    &.stage-2 {
      &:before {
        content: '2';
      }
    }
    &.stage-3 {
      padding-bottom: 0;
      &:before {
        content: '3';
      }
    }
  }
}