.filter {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 20px 20px 11px;
  margin-bottom: 40px;
  .row {
    margin: 0 -10px;
  }
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
  .form-control {
    height: 36px;
    line-height: 36px;
    padding: 0 20px 0 9px;
    font-size: 16px;
    color: #808080;
    margin-bottom: 12px;
    appearance: none;
  }
  input.form-control {
    padding: 0 9px 0 9px;
  }
  .form-group {
    .form__label {
      left: 9px;
      top: 1px;
      font-size: 8px;
    }
    .form-checkbox+label.form__label {
      display: inline-flex;
      align-items: center;
      user-select: none;
      font-weight: normal;
      line-height: 36px;
      font-size: 16px;
      color: #808080;
      cursor: pointer;
      left: 0;
      top: 0;
      @include media-breakpoint-down(lg) {
        font-size: 12px;
        white-space: nowrap;
      }
    }
    &.checkbox-group {
      line-height: 36px;
      font-size: 16px;
      color: grey;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      @include media-breakpoint-down(lg) {
        font-size: 14px;
      }
      .form-check-inline {
        position: relative;
      }
      .form-check-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
      .form-check-label {
        height: 36px;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        padding: 0 10px;
        border: 1px solid #e0e0e0;
        border-radius: 18px;
        margin-bottom: 12px;
        &:hover {
          background-color: #36a2ba;
          color: #ffffff;
        }
      }
      .form-check-input:checked + .form-check-label {
        background-color: #36a2ba;
        color: #ffffff;
      }
    }
    &.radio-group {
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      color: grey;
      margin-bottom: 12px;
      display: flex;
      flex-wrap: nowrap;
      align-content: stretch;
      width: 100%;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      justify-content: space-around;
      @include media-breakpoint-down(lg) {
        font-size: 12px;
      }
      .form-check-inline {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        margin-right: 0;
        border-right: 1px solid #e0e0e0;
        width: 100%;
        white-space: nowrap;

      &:first-child {
          .form-check-label {
            border-radius: 5px 0 0 5px;
          }
        }
        &:last-child {
          border-right: 0;
          .form-check-label {
            border-radius: 0 5px 5px 0;
          }
        }
        .form-check-label {
          width: 100%;
          height: 36px;
          text-align: center;
          cursor: pointer;
          padding: 0 6px;
          &:hover {
            background-color: #36a2ba;
            color: #ffffff;
          }
        }
        .form-check-input:checked + .form-check-label {
          background-color: #36a2ba;
          color: #ffffff;
        }
      }
      .form-check-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
    }
    &.colors-group {
      .color-toggler {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 1px solid #cccccc;
        float: right;
        background: url('../img/filter/open.svg') center 6px no-repeat transparent;
        &[aria-expanded="true"] {
          background: url('../img/filter/close.svg') center center no-repeat transparent;
        }
        outline: 0;
      }
      .form-check-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
      .form-check-inline {
        align-items: center;
        padding-left: 0;
        margin-right: 2px;
        @include media-breakpoint-down(lg) {
          margin-right: 0;
        }
      }
      .form-check-input+label.form-check-label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-weight: normal;
        font-size: 0;
        line-height: 12px;
        color: #808080;
        cursor: pointer;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        @include media-breakpoint-down(lg) {
          width: 18px;
          height: 18px;
        }
      }
      .form-check-input+label::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 0;
        background-color: #F0F0F0;
        border-radius: 100%;
        margin-right: 0;
        background-repeat: no-repeat;
        background-position: center center;
        @include media-breakpoint-down(lg) {
          width: 18px;
          height: 18px;
        }
      }
      #filterColorBlack+label::before {
        background-color: #000000;
      }
      #filterColorSilver+label::before {
        background-color: rgb(193, 193, 193);
        background-image: linear-gradient(rgb(240, 240, 240), rgb(193, 193, 193));
      }
      #filterColorWhite+label::before {
        background-color: #ffffff;
        border: 1px solid #cccccc;
      }
      #filterColorGray+label::before {
        background-color: rgb(156, 153, 153);
      }
      #filterColorBlue+label::before {
        background-color: rgb(51, 77, 255);
      }
      #filterColorRed+label::before {
        background-color: rgb(252, 72, 41);
      }
      #filterColorGreen+label::before {
        background-color: rgb(53, 186, 43);
      }
      #filterColorBrown+label::before {
        background-color: rgb(146, 101, 71);
      }
      #filterColorBeige+label::before {
        background-color: rgb(241, 217, 178);
      }
      #filterColorAzure+label::before {
        background-color: rgb(54, 161, 255);
      }
      #filterColorOrange+label::before {
        background-color: rgb(250, 190, 0);
        background-image: linear-gradient(rgb(255, 237, 2), rgb(253, 156, 0));
      }
      #filterColorRose+label::before {
        background-color: rgb(197, 23, 45);
      }
      #filterColorViolent+label::before {
        background-color: rgb(153, 102, 204);
      }
      #filterColorYellow+label::before {
        background-color: rgb(253, 233, 15);
      }
      #filterColorSiena+label::before {
        background-color: rgb(255, 153, 102);
      }
      #filterColorPurple+label::before {
        background-color: rgb(255, 192, 203);
      }

      .form-check-inline {
        .form-check-input:checked + label::before,#filterColorOrange:checked+label::before,#filterColorSilver:checked+label::before {

          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='3 -4 20 20'%3E%3Cpath d='M13-4C7.5-4 3 .5 3 6s4.5 10 10 10 10-4.5 10-10S18.5-4 13-4zm5.7 7.7l-6 6c-.4.4-1 .4-1.4 0L8.4 6.8c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 7.5l5.2-5.2c.4-.4 1-.4 1.4 0 .5.4.5 1.1.1 1.4z' opacity='.2'/%3E%3Cpath d='M18.7 3.7l-6 6c-.4.4-1 .4-1.4 0L8.4 6.8c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 7.5l5.2-5.2c.4-.4 1-.4 1.4 0 .5.4.5 1.1.1 1.4z' opacity='.5' fill='%23fff'/%3E%3C/svg%3E");
          background-size: 20px 20px;
        }

        /* стили при наведении курсора на checkbox */
        .form-check-input:not(:disabled):not(:checked) + label:hover::before {
          border-color: #b3d7ff;
        }

        /* стили для активного состояния чекбокса (при нажатии на него) */
        .form-check-input:not(:disabled):active + label::before {
          background-color: #b3d7ff;
          border-color: #b3d7ff;
        }

        /* стили для чекбокса, находящегося в фокусе */
        .form-check-input:focus + label::before {
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }

        /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
        .form-check-input:focus:not(:checked) + label::before {
          border-color: #80bdff;
        }

        /* стили для чекбокса, находящегося в состоянии disabled */
        .form-check-input:disabled + label::before {
          background-color: #e9ecef;
        }
      }
    }
  }
  select.form-control {
    background: url("../img/filter/open.svg") right 9px  top 16px no-repeat transparent;
  }
  .duo {
    .col-6:first-child{padding-right: 5px}
    .col-2:first-child{padding-right: 5px}
    .col-6:last-child{padding-left: 5px}
  }
  .same {
      .col-6:first-child {
        padding-right: 0;

        .form-control {
          border-radius: 5px 0 0 5px;
        }
      }
      .col-6:last-child {
        padding-left: 0;

        .form-control {
          border-radius: 0 5px 5px 0;
          border-left: 0;
        }
      }
  }
  .btn {
    font-size: 16px;
    height: 36px;
    line-height: 36px;
  }
  .link {
    font-size: 15px;
    height: 36px;
    line-height: 36px;
    display: block;
    color: #36A2BA;
    span {
      display: inline-block;
      width: 15px;
      height: 15px;
      background: url("../img/filter/open_blue.svg") center top 8px no-repeat transparent;
      margin-left: 5px;
    }
    cursor: pointer;
    &[aria-expanded="true"] {
      span {
        transform: rotate(180deg);
        background-position: center top 1px;
      }
    }
  }
  .irs--flat {
    .irs-line {
      top: 18px;
      height: 4px;
      background-color: #cccccc;
      border-radius: 4px;
      cursor: pointer;
    }
    .irs-bar {
      top: 18px;
      height: 4px;
      background-color: #36A2BA;
    }
    .irs-handle {
      top: 11px;
      width: 19px;
      height: 19px;
      background: #36A2BA;
      border: 3px solid #F0F0F0;
      border-radius: 100%;
      cursor: pointer;
      &>i:first-child {
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        width: 0;
        height: 100%;
        margin-left: -1px;
        background-color: transparent;
      }
    }
    .irs-from, .irs-single, .irs-to,.irs-min, .irs-max {
      display: none;
    }
  }
  .range-from {
    font-size: 14px;
    color: #808080;
    position: absolute;
    bottom: -6px;
    left: 0;
  }
  .range-to {
    font-size: 14px;
    color: #808080;
    position: absolute;
    bottom: -6px;
    right: 0;
  }
}