@import 'lg-variables';
@import 'lg-mixins';
$thumbnails-margin: 5px;

.lg-outer {
    .lg-thumb-outer {
        background-color: $lg-thumb-bg;
        display: inline-block;
        width: 100%;
        max-height: 350px;

        &.lg-grab {
            .lg-thumb-item {
                @include grab-cursor;
            }
        }

        &.lg-grabbing {
            .lg-thumb-item {
                @include grabbing-cursor;
            }
        }

        &.lg-dragging {
            .lg-thumb {
                @include transition-duration(0s !important);
            }
        }
        &.lg-rebuilding-thumbnails {
            .lg-thumb {
                @include transition-duration(0s !important);
            }
        }

        &.lg-thumb-align-middle {
            text-align: center;
        }
        &.lg-thumb-align-left {
            text-align: left;
        }
        &.lg-thumb-align-right {
            text-align: right;
        }
    }

    &.lg-single-item {
        .lg-thumb-outer {
            display: none;
        }
    }

    .lg-thumb {
        padding: 5px 0;
        height: 100%;
        margin-bottom: -$thumbnails-margin;
        display: inline-block;
        vertical-align: middle;
        @media (min-width: 768px) {
            padding: 10px 0;
        }
    }

    .lg-thumb-item {
        cursor: pointer;
        float: left;
        overflow: hidden;
        height: 100%;
        border-radius: 2px;
        margin-bottom: $thumbnails-margin;
        will-change: border-color;
        @media (min-width: 768px) {
            border-radius: 4px;
            border: 2px solid #fff;
            @include transition(border-color 0.25s ease);
        }

        &.active,
        &:hover {
            border-color: $lg-theme-highlight;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }

    &.lg-can-toggle {
        .lg-item {
            padding-bottom: 0;
        }
    }

    .lg-toggle-thumb {
        &:after {
            content: '\e1ff';
        }
    }
    &.lg-animate-thumb {
        .lg-thumb {
            @include transition-timing-function(
                cubic-bezier(0.215, 0.61, 0.355, 1)
            );
        }
    }
}
