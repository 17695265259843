.content {
  padding: 0 0 60px;
  margin-top: 50px;
  @include media-breakpoint-down(lg) {
    padding: 0 0 35px;
    margin-top: 30px;
  }
  &.contact {
    padding-bottom: 30px;
    .offices {
      margin-top: 45px;
    }
  }
  h1 {
    margin-bottom: 20px;
  }
  .main {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    .left-side {
      width: 885px;
      margin-right: 20px;
      @include media-breakpoint-down(lg) {
        width: auto;
        margin: 0;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        display: block;
      }
    }
    .right-side {
      .banner {
        margin-top: 0;
        width: 275px;
        display: block;
        top: 200px;
        z-index: 700;
      }
    }
  }
  .product-list {
    .noFavProducts {
      text-align: center;
      color: #888;
      font-size: 16px;
    }
    .card {
      display: flex;
      flex-flow: row;
      border-top: 1px solid #DDDDDD;
      padding: 15px 16px;
      text-decoration: none;
      position: relative;
      @include media-breakpoint-down(md) {
        flex-flow: column;
      }
      &:hover {
        border-top: 1px solid transparent;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
      }
      .img {
        width: 205px;
        height: 154px;
        margin-right: 15px;
        overflow: hidden;
        img {
          width: 205px;
          height: 154px;
          border-radius: 5px;
        }
        @include media-breakpoint-down(md) {
          width: 100%;
          height: unset;
          margin: 0 0 15px;
          border-radius: 10px;
          overflow: hidden;
          img{
            width: 100%;
            height: auto;
          }
        }
      }
      .thumb-item {
        .tmb-wrap-table {
          height: 150px;
          width: 201px;
          margin-left: 2px;
          @include media-breakpoint-down(md) {
            width: 98%;
            height: 95%;
          }
          div {
            border-bottom: 3px solid $white;
            border-right: 2px solid transparent;
            border-left: 2px solid transparent;
          }
        }
        .tmb-wrap-table div.active {
          border-bottom: 3px solid #36A2BA;
        }
        .owl-stage {
          padding-left: 0!important;
        }
        .owl-dots {
          display: none;;
        }
        .thumb {
          position: relative;
          &:after {
            display: none;
            content: '';
            position: absolute;
            top:0;
            left: 0;
            bottom: 0;
            right: 0;
            background: #222222;
            opacity: 0.8;
            z-index: 0;
          }
          &.more {
            &:after {
             display: block;
            }
          }
          .moreitems {
            font-size: 15px;
            text-align: center;
            position: absolute;
            top:0;
            left: 0;
            bottom: 0;
            right: 0;
            color: $white;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .card-right {
        flex: auto;
        position: relative;
        height: 154px;
        @include media-breakpoint-down(sm) {
          height: 240px;
        }
        .card-maininfo {
          width: 289px;
          padding-right: 15px;
          @include media-breakpoint-down(sm) {
            width: 100%;
            padding-right: 0px;
            padding-bottom: 15px;
          }
          .title {
            font-weight: bold;
            font-size: 17px;
            line-height: 22px;
            color: $black;
            margin-bottom: 9px;
          }
          .subinfo {
            font-weight: normal;
            font-size: 15px;
            line-height: 22px;
            color: #808080;
          }
          .subinfo1 {
            width: 180px;
            padding-right: 15px;
          }
        }
        .cost {
          font-weight: bold;
          font-size: 17px;
          line-height: 22px;
          padding-right: 15px;
          width: 140px;
          color: $black;
          .credit {
            font-weight: normal;
            font-size: 15px;
            line-height: 19px;
            color: #36A2BA;
            margin-top: 9px;
            @include media-breakpoint-down(lg) {
              margin-top: 3px;
            }
          }
        }
        .year {
          font-weight: normal;
          font-size: 17px;
          line-height: 22px;
          color: $black;
          width: 56px;
        }
        .run {
          font-weight: normal;
          font-size: 17px;
          line-height: 22px;
          color: $black;
        }
        .checked {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 26px;
          background: url("../img/shared/checked.svg") left top no-repeat transparent;
          padding-left: 29px;
          color: $black;
          font-size: 14px;
          line-height: 13px;
          width: 115px;
        }
        .get {
          position: absolute;
          bottom: 0;
          right: 0;
          .phone {
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            color: #36A2BA;
            margin-bottom: 10px;
            cursor: pointer;
          }
          .dealer-phone {
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #36a2ba;
            margin-bottom: 10px;
            cursor: pointer;
          }
          .btn {
            width: 125px;
          }
        }
      }
      .favourite {
        height: 45px;
        width: 45px;
        position: absolute;
        right: 16px;
        top: 15px;
        display: flex;
        align-items: center;
        border: 1px solid #808080;
        border-radius: 5px;
        background: $white;
        z-index: 900;
        .svg {
          width: 15px;
          height: 14px;
          margin: 0 auto;
          path {
            fill: none;
            stroke: #808080;
            stroke-width: 4px;
          }
        }
        &.active {
          .svg {
            path {
              fill: #E73D3D;
              stroke: #E73D3D;
            }
          }
        }
        @include media-breakpoint-up(lg) {
          &:hover {
            .svg {
              path {
                fill: #E73D3D;
                stroke: #E73D3D;
              }
            }
          }
        }
      }
    }
  }
  .takeblock {
    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #36A2BA;
      margin-bottom: 6px;
    }
    ul, li {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
    ul {
      margin-bottom: 50px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
      }
    }
  }
  .attention-block {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    padding: 20px;

    margin: 0 auto 30px;
    &.thin {
      max-width: 220px;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }
    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 20px;
      height: 22px;
      padding-left: 31px;
      background: url("../img/shared/attention.svg") left center no-repeat transparent;
    }
    .text {
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      text-align: justify;
      color: #000000;
    }
  }
  .hr {
    width: 100%;
    height: 1px;
    background: #DDDDDD;
    margin: 10px 0;
  }
}
.about-photo {
  width: 100%;
  height: 450px;
  background: url("../img/shared/about.jpg") center center no-repeat transparent;
  background-size: cover;
  margin-top: -30px;
  @include media-breakpoint-down(lg) {
    height: 300px;
  }
}
.about {
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-align: justify;
    color: #252627;
  }
  article {
    margin-bottom: 50px;
  }
}
