.breadcrumbs {
  margin-top: 58px;
  margin-bottom: -30px;
  @include media-breakpoint-down(lg) {
    margin-top: 20px;
    margin-bottom: 0;
  }
  .breadcrumb-item {
    display: inline-block;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    &:after {
      content: ' → ';
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    a {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #333333;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    span {
      opacity: 0.5;
    }
  }
}