.awards {
  .award {
    position: relative;
    padding-left: 99px;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 20px;
    height: 64px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 15px;
      width: 64px;
      height: 64px;
      background: url('../img/awards/award.svg') center center no-repeat #F0F0F0;
      border-radius: 5px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 14px;
      line-height: 17px;
    }

  }
}