.credit-calc {
  background: #F0F0F0;
  border-radius: 5px;
  padding: 15px 20px 20px 20px;
  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    padding-right: 0;
  }
  .checked {
    height: 26px;
    background: url("../img/shared/checked.svg") left top no-repeat transparent;
    padding-left: 25px;
    color: $black;
    font-size: 14px;
    line-height: 13px;
    width: 111px;
    margin: 0 0 0 auto;
  }
  .cost {
    font-weight: bold;
    font-size: 32px;
    line-height: 41px;
    margin-top: 12px;
    color: #000000;
    margin-bottom: 2px;
  }
  .credit {
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    color: #36A2BA;
    margin-bottom: 14px;
    visibility: hidden;
  }
  .selection {
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 10px;
    .hint {
      font-weight: bold;
      font-size: 10px;
      line-height: 18px;
      color: #FFFFFF;

      display: inline-block;
      text-align: center;

      margin-left: 5px;
      position: relative;
      height: 18px;
      &:after {
        content: '?';
        display: block;
        width: 18px;
        height: 18px;
        background: #CCCCCC;
        border-radius: 100%;
        position: absolute;
        top: 2px;
        left: 0;
        cursor: default;
      }
    }
  }
  .policy {
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    color: #808080;
    margin-top: 20px;
    a {
      color: #808080;
      text-decoration: underline;
      &:hover {
        color: #808080;
        text-decoration: none;
      }
    }
  }
  .form-group {
    &.last {
      margin-bottom: 20px;
    }
    &.slider {
      margin-bottom: 0;
    }
    .irs--flat {
      .irs-line {
        top: 18px;
        height: 4px;
        background-color: #cccccc;
        border-radius: 4px;
        cursor: pointer;
      }
      .irs-bar {
        top: 18px;
        height: 4px;
        background-color: #36A2BA;
      }
      .irs-handle {
        top: 6px;
        width: 29px;
        height: 29px;
        background: #36A2BA;
        border: 3px solid #F0F0F0;
        border-radius: 100%;
        cursor: pointer;
        &>i:first-child {
          position: absolute;
          display: block;
          top: 0;
          left: 50%;
          width: 0;
          height: 100%;
          margin-left: -1px;
          background-color: transparent;
        }
      }
      .irs-from, .irs-single, .irs-to,.irs-min, .irs-max {
        display: none;
      }
    }
    .range-from {
      font-size: 14px;
      color: #808080;
      position: absolute;
      bottom: -6px;
      left: 0;
    }
    .range-to {
      font-size: 14px;
      color: #808080;
      position: absolute;
      bottom: -6px;
      right: 0;
    }
  }
  .form-control {
    &.creditVal {
      font-weight: bold;
      font-size: 24px;
      line-height: 31px;
      color: #000000;
      background: transparent;
      border: 0;
      padding: 0;
      opacity: 1;
      height: 31px;
    }
  }
  .form__label {
    display: block;
    &.creditVal {
      font-weight: normal;
      font-size: 15px;
      line-height: 15px;
      color: #808080;
      position: static;
      top: 0;
      left: 0;
      margin-bottom: 0;
    }
  }
}