.form-group {
  position: relative;
  margin-bottom: 10px;
  min-height: 36px;
  .form__label {
    position: absolute;
    font-size: 9px;
    color: $gray-dark;
    top: 3px;
    left: 17px;
    display: none;
  }
  input {
    &::placeholder {
      font-size: 15px;
      color: $gray-dark;

    }
    &:placeholder-shown + .form__label {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }
  .form-control {
    display: block;
    width: 100%;
    height: 42px;
    padding: 6px 16px;
    font-family: $font-family-base;
    font-size:16px;
    font-weight: normal;
    line-height: 30px;
    color: $black;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $gray-light;

    border-radius: 5px;

    box-shadow: none;
    @include transition($input-transition);

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    // Remove select outline from select box in FF
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $input-color;
    }

    &:focus {
      color: $black;
      background-color: $white;
      border-color: $gray-light;
      outline: 0;
      box-shadow: none;
    }

    // Placeholder
    &::placeholder {
      color: $gray-dark;
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: 1;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
      background-color: $white;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 0.5;
    }
  }
  .form-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .form-checkbox+label.form__label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #808080;
    cursor: pointer;
    left: 0;
    top: 0;
  }
  .form-checkbox+label::before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 19px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 0;
    background-color: #F0F0F0;
    border-radius: 100%;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  .form-checkbox:checked+label::before {
    background-color: #F0F0F0;
    background-image: url("../img/shared/check.svg");
  }
  /* стили при наведении курсора на checkbox */
  .form-checkbox:not(:disabled):not(:checked)+label:hover::before {
    border-color: #b3d7ff;
  }
  /* стили для активного состояния чекбокса (при нажатии на него) */
  .form-checkbox:not(:disabled):active+label::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }
  /* стили для чекбокса, находящегося в фокусе */
  .form-checkbox:focus+label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
  .form-checkbox:focus:not(:checked)+label::before {
    border-color: #80bdff;
  }
  /* стили для чекбокса, находящегося в состоянии disabled */
  .form-checkbox:disabled+label::before {
    background-color: #e9ecef;
  }
}