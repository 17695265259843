.map {
  #map {
    width: 100%;
    height: 600px;
    @include media-breakpoint-down(md) {
      height: 500px;
    }
  }
  .container {
    position: relative;
    .mapinfo {
      background: #FFFFFF;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      padding: 20px;
      position: absolute;
      top: 160px;
      @include media-breakpoint-down(md) {
        top: 20px;
      }
      left: 0;
      z-index: 998;
      .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 90%;
        color: $black;
        margin-bottom: 30px;
      }
      .address {
        font-weight: bold;
        font-size: 18px;
        line-height: 90%;
        color: $black;
        margin-bottom: 10px;
      }
      .time {
        font-weight: normal;
        font-size: 14px;
        line-height: 90%;
        color: $black;
        margin-bottom: 30px;
      }
      .btn {
        height: 36px;
        line-height: 36px;
        padding: 0 10px;
        font-size: 16px;
        margin-bottom: 20px;
      }
      .phone {
        font-weight: bold;
        font-size: 18px;
        line-height: 90%;
        color: #000000;
        a {
          color: #000;
          text-decoration: none;
        }
        display: none;
        margin-bottom: 33px;
      }
      .sendaddress {
        .header {
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 10px;
        }
        .social {
          display: flex;
          flex-flow: nowrap;
          justify-content: space-between;
          a {
            width: 31px;
            height: 31px;
            display: block;
            background-repeat: no-repeat;
            background-position: center center;
            background-color: transparent;
            &.Telegram {
              background-image: url("../img/shared/Telegram.svg");
            }
            &.WhatsApp {
              background-image: url("../img/shared/WhatsApp.svg");
            }
            &.VK {
              background-image: url("../img/shared/VK.svg");
            }
            &.Viber {
              background-image: url("../img/shared/Viber.svg");
            }
            &.Facebook {
              background-image: url("../img/shared/Facebook.svg");
            }
            &.Instagram {
              background-image: url("../img/shared/Instagram.svg");
            }
          }
        }
      }
    }
  }
}
.shortMapTitle {
  .map {
    .container {
      .mapinfo {
        .title {
          display: none;
        }
        .btn {
          display: none;
        }
        .phone {
          display: block;
        }
      }
    }
  }
  &.contact {
    .map {
      .container {
        .mapinfo {
          .address, .time, .phone {
            display: none;
          }
        }
      }
    }
  }
}
.ya-share2__container_shape_round.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon:not(.ya-share2__icon_messenger-contact):not(.ya-share2__icon_more):not(.ya-share2__icon_copy) {
  background-size: 26px 26px
}
.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
  width: 30px;
  height: 30px;
}
.ya-share2__container_shape_round {
  .ya-share2__badge {
    margin-right: 20px;
  }
}
.ya-share2__item_service_whatsapp .ya-share2__badge, .ya-share2__item_service_whatsapp .ya-share2__mobile-popup-badge {
  margin-right: 0;
}