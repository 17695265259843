.stage-icons {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;

  margin-bottom: 50px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 30px;
  }
  @include media-breakpoint-down(xs) {
    flex-flow: wrap;
  }

  .stage {
    @include media-breakpoint-down(xs) {
      flex: 1 0 25%;
      margin-bottom: 20px;
    }
    .img {
      display: flex;
      height: 203px;
      align-items: center;
      margin-bottom: 15px;
      text-align: center;
      justify-content: center;
      .svg {
        max-height: 100%;
      }
      @include media-breakpoint-down(lg) {
        height: 150px;
        .svg {
          width: 90%;
          max-height: 100%;
        }
      }
      @include media-breakpoint-down(xs) {
        height: 100px;
      }
    }
    .title {
      font-size: 18px;
      line-height: 23px;

      color: #000000;
      max-width: 98%;
      display: flex;
      justify-content: center;
      min-width: 245px;
      @include media-breakpoint-down(lg) {
        min-width: 150px;
        font-size: 14px;
        line-height: 18px;
      }
      &:before {
        font-size: 24px;
        line-height: 31px;
        font-weight: bold;
        color: #000000;
        padding-right: 7px;
        display: block;
        float: left;
        margin-top: -4px;
        @include media-breakpoint-down(lg) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
    &.stage-1 {
      .title {
        justify-content: flex-start;
        &:before {
          content: '1';
        }
      }
    }
    &.stage-2 {
      .title {
        &:before {
          content: '2';
        }
      }
    }
    &.stage-3 {
      .title {
        &:before {
          content: '3';
        }
      }
    }
    &.stage-4 {
      .title {
        justify-content: flex-end;
        &:before {
          content: '4';
        }
      }
    }
  }
}