.topline {
  height: 121px;
  background: $white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  z-index: 999;
  @include media-breakpoint-down(lg) {
    height: 101px;
  }
  @include media-breakpoint-down(sm) {
    height: 50px;
  }
  .container {

    .block {
      border-bottom: 1px solid #E5E5E5;
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-down(sm) {
        flex-direction: row-reverse;
      }
      @include media-breakpoint-down(sm) {
        border-bottom: 0;
      }
      & > .contact {
        display: block;
        height: 70px;
        line-height: 70px;
        font-size: 18px;
        color: #252627;
        text-decoration: none;
        white-space: nowrap;
        padding-left: 24px;
        background: url("../img/shared/tel.svg") left center no-repeat transparent;
        @include media-breakpoint-down(lg) {
          font-size: 18px;
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }
  .logo {
    .svg {
      height: 70px;
      width: 238px;
      @include media-breakpoint-down(lg) {
        width: 200px;
        height: 50px;
      }
      @include media-breakpoint-down(sm) {
        width: 238px;
      }
    }
    display: block;
  }

  .topline-nav {
    display: flex;
    justify-content: space-between;
    a {
      font-weight: normal;
      font-size: 18px;
      line-height: 70px;
      height: 70px;
      display: block;
      margin: 0 20px;
      color:#252627;
      text-decoration: none;
      &:hover {
        color: $red;
      }
      &.place {
        padding-left: 25px;
        background: url("../img/topline/place.svg") left center no-repeat transparent;
        background-size: 17px 24px;
        @include media-breakpoint-down(lg) {
          span {
            display: block;
            min-width: 100px;
          }
        }
      }
      &.callback {
        padding-left: 32px;
        background: url("../img/topline/phone.svg") left center no-repeat transparent;
        background-size: 23px 24px;
      }
      &.application {
        padding-left: 44px;
        background: url("../img/topline/apportion.svg") left center no-repeat transparent;
        background-size: 35px 25px;
      }
      @include media-breakpoint-down(lg) {
        font-size: 12px;
        margin: 0 10px;
        line-height: 17px;
        padding-top: 7px;
        padding-bottom: 7px;
        height: 50px;
      }
    }
  }
  .navbar {
    padding: 7px 0;
    position: static;
    .navbar-toggler {
      border: 0;
      outline: none;
      padding: 0;
    }
    .navbar-collapse {
      background: $white;
      border-bottom: 6px solid $red;
      padding: 15px;
      box-shadow: 0px 50px 40px rgba(0, 0, 0, 0.2);
      position: absolute;
      left: 0;
      right: 0;
      top: 51px;
      &.show {
        display: block;
      }
      a {
        font-weight: normal;
        font-size: 20px;
        line-height: 44px;
        height: 44px;
        display: block;
        margin: 0;
        color: $black;
        text-decoration: none;
        white-space: nowrap;
        &:hover {
          color: $red;
        }
        &.place {
          margin-top: 20px;
          text-align: center;
          height: auto;
          position: static!important;
          font-size: 0;
          .ymaps-2-1-78-geolink-popup {
            right: 20px!important;
            top: 100px !important;
            left: auto!important;
          }
          .svg {
            path {
              fill: $white;
            }
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background: $red;
            padding: 7px;
            margin: 0 auto;
          }
          span {
            display: block;
            font-size: 12px;
            line-height: 16px;
            color: $black;
            margin: 5px auto 15px;
          }
          &:before,&:after {
            display: none!important;
          }

        }
        &.callback {
          margin-top: 20px;
          text-align: center;
          height: auto;
          .svg {
            path {
              fill: $white;
            }
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background: $red;
            padding: 7px;
            margin: 0 auto;
          }
          span {
            display: block;
            font-size: 12px;
            line-height: 16px;
            color: $black;
            margin: 5px auto 15px;
          }
        }
        &.favourite {
          margin-top: 20px;
          text-align: center;
          height: auto;
          .svg {
            path {
              fill: $red;
              stroke: $white;
            }
            width: 27px;
            height: 27px;
            margin: 0 auto;
          }
          i {
            width: 50px;
            height: 50px;
            background: $red;
            border-radius: 100%;
            margin: 0 auto;
            display: block;
          }
          span {
            display: block;
            font-size: 12px;
            line-height: 16px;
            color: $black;
            margin: 5px auto 15px;
          }
        }

        &.contact {
          font-size: 18px;
          height: 42px;
          line-height: 42px;
          color: $white;
          &:hover {
            color: $white;
          }
        }
      }
      .nav {
        display: block;
      }
    }
  }
  .topnav {
    background: #FFFFFF;
    height: 50px;
    .container {
      display: flex;
      justify-content: space-between;
      border-bottom: 0;
    }
    .nav {
      .nav-item {
        a {
          display: block;
          height: 50px;
          margin-right: 40px;
          line-height: 50px;
          font-weight: normal;
          font-size: 16px;
          color: #252627;
          &:hover, &.active {
            color: #252627;
            text-decoration: none;
            border-bottom: 1px solid $red;
          }
          &.active {
            font-weight: 700;
          }
          @include media-breakpoint-down(lg) {
            margin-right: 25px;
            font-size: 16px;
            height: 50px;
            line-height: 50px;
          }
        }
      }
    }
    .favourite {
      height: 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .svg {
        width: 22px;
        height: 21px;
      }
      &.active {
        .svg {
          path {
            fill: #E73D3D;
          }
        }
      }
      @include media-breakpoint-down(lg) {
        height: 50px;
      }
    }
  }
}

.product-card {
  display: flex;
  align-content: flex-start;
  .product-img {
    width: 93px;
    img {
      width: 93px;
      height: 70px;
      border-radius: 5px;
    }
    .vin {
      text-align: center;
      font-weight: normal;
      font-size: 9px;
      line-height: 12px;
      color: darkgray;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
  .product-info {
    padding-left: 9px;
    .title {
      font-weight: bold;
      font-size: 17px;
      line-height: 22px;
      color: $black;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .subtitle {
      font-weight: normal;
      font-size: 10px;
      line-height: 13px;
      color: $black;
      .year {
        margin-right: 8px;
        display: inline-block;
      }
      .run {
        display: inline-block;
      }
    }
    .cost {
      font-weight: bold;
      font-size: 17px;
      line-height: 22px;
      margin-top: 13px;
      color: $black;
    }
  }
}