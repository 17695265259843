.banks {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
    display: flex;
    list-style: none;
    margin: 0 0 9px;
    border: 1px solid #DEDEDF;
    box-sizing: border-box;
    border-radius: 5px;
    height: 93px;
    text-align: center;

    flex-basis: 187px;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      flex-basis: 48%;
    }
    img {
      max-width: 80%;
    }
  }

}