@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

$font-family-base:            'PT Sans', sans-serif;
$headings-font-family:        'PT Sans', sans-serif;
$line-height-base: 1.625;
$font-family-sans-serif: $font-family-base;

$h1-font-size:                40px;
$h2-font-size:                28px;
$h3-font-size:                20px;
$h4-font-size:                16px;
$description-font-size:       12px;
$display-font-size:           52px;

$subheader-font-size:         15px;
$subheader-letter-spacing:    2%;
$subheader-weight:            600;

:root {
  --font-family-sans-serif : 'PT Sans', sans-serif;
}
body {
  font-family: $font-family-base;
}

h1 {
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;
  color: $black;
  @include media-breakpoint-down(lg) {
    font-size: 26px;
    line-height: 32px;
  }
  @include media-breakpoint-down(md) {
    font-size: 24px;
    line-height: 28px;
  }
}
h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  color: $black;
  margin-bottom: 20px;
  @include media-breakpoint-down(lg) {
    font-size: 22px;
    line-height: 28px;
  }
  @include media-breakpoint-down(md) {
    font-size: 20px;
    line-height: 24px;
  }
}