footer {
  padding: 40px 0 100px;
  background: #DDDDDD;
  .logo {
    .svg {
      width: 238px;
    }
  }
  .copy {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $black;
    margin-bottom: 27px;
  }
  .attention {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #808080;
    @include media-breakpoint-down(sm) {
      .attention-visible {

        display: block;
        position: relative;
        &.active:after {
          display: none;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-image: url("../img/filter/open.svg"), linear-gradient(
                  0deg
              ,#ddd 0,rgba(204,204,204,0) 100%);
          background-repeat: no-repeat;
          background-position: bottom center;
          height: 80px;
        }
      }
      .attention-hidden {
        display: none;
      }
    }
  }
  .social {
    margin: 20px 0 80px;
    @include media-breakpoint-down(sm) {
      margin-top: 40px;
      margin-bottom: 30px;
    }
    a {
      width: 25px;
      height: 25px;
      margin-right: 30px;
      display: block;
      float: left;
      &.instagram {
        background: url("../img/footer/instagram.svg") center center no-repeat transparent;
      }
      &.vk {
        background: url("../img/footer/vkontakte.svg") center center no-repeat transparent;
      }
      &.facebook {
        background: url("../img/footer/facebook.svg") center center no-repeat transparent;
      }
    }
  }
  .footer-navigate {
    flex-wrap: nowrap;
    display: flex;
    justify-content: flex-end;
    @include media-breakpoint-down(sm) {
      justify-content: center;
      margin-bottom: 30px;
    }
  }
  .nav {
    flex-wrap: wrap;
    flex-flow: column;
    margin-left: 70px;
    @include media-breakpoint-down(lg) {
      margin-left: 25px;
    }
    @include media-breakpoint-down(sm) {
      margin-left: 10px;
      margin-right: 10px;
    }
    .nav-item {
      display: block;
      padding: 0 0 1px;
      margin: 0 0 15px;
      a {
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
        border-bottom: 1px solid transparent;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
          line-height: 18px;
        }
        &:hover {
          text-decoration: none;
          border-bottom: 1px solid $red;
        }
        &.active {
          font-weight: bold;
          &:hover {
            text-decoration: none;
            border-bottom: 1px solid transparent;
            cursor: default;
          }
        }
      }
    }
  }
}