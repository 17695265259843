.gallery {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  @include media-breakpoint-down(md) {
    display: flex;
    flex-flow: column;
    .owl-stage {
      padding-left: 0!important;
    }
  }
  .gallery-main {
    position: relative;

    .owl-nav {
      .owl-prev {
        position: absolute;
        top:50%;
        left: 20px;
      }
      .owl-next {
        position: absolute;
        top:50%;
        right: 20px;
      }
      .owl-prev, .owl-next {
        font-size: 60px;
        line-height: 60px;
        height: 60px;
        margin-top: -30px;
        color: #fff;
        cursor: pointer;
        display: none;
        outline: 0;
        &.disabled {
          color: #cccccc;
          cursor: default;
        }
      }
    }
    &:hover {
      .owl-nav {
        .owl-prev, .owl-next {
          display: block;
        }
      }
    }
    .gallery-image {
      width: 100%;
      display: none;
      overflow: hidden;
      transition: all .2s ease-in-out;
      img {
        width: 100%;
        height: auto;
        -moz-transition: all 1s ease-out;
        -o-transition: all 1s ease-out;
        -webkit-transition: all 1s ease-out;
        transition: all 1s ease-out;
        &:hover {
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -o-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        height: unset;
        margin: 0 0 15px;
        border-radius: 10px;
        overflow: hidden;
        display: block;
        img{
          width: 100%;
          height: auto;
          border-radius: 5px;
        }
      }
    }
    .active {
      .gallery-image {
        display: block;
      }
    }
  }
  .gallery-thumbs {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      display: none;
    }
    .gallery-thumb {
      flex: 0 0 20%;
      display: flex;
      margin-top: 1px;
      padding-left: 1px;
      position: relative;
      cursor: pointer;
      align-items: center;
      transition: all .2s ease-in-out;
      &:first-child, &:nth-child(6) {
        padding-left: 0;
      }
      img {
        width: 100%;
        height: auto;
      }
      &:after {
        display: block;
        content: '';
        position: absolute;
        top:0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #222222;
        opacity: 0.5;
        z-index: 10;
      }
      &.active {
        &:after {
          opacity: 0;
        }
      }
      &.hidden {
        display: none;
      }
      &.more {
        &:after {
          opacity: 0.8;
        }
      }
      .moreitems {
        font-size: 15px;
        text-align: center;
        position: absolute;
        top:0;
        left: 0;
        bottom: 0;
        right: 0;
        color: $white;
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}